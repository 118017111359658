<template>
  <div style="overflow-y: overlay;height: 62.2vh;width:100%">
    <div :style="isDesktop() ? 'width:68%;min-width:520px;' : 'width:100%;'">
      <a-spin :spinning="spinning">
        <template v-if='formData'>
          <!--         <div v-for="(diffItem, index) in formData.difficulties" :key="diffItem.id">
          <div class="coef_item" style="display:flex">
            <div style="width:35px;display:flex;justify-content:space-between;align-items:center">
              <div style="background-color:rgba(0, 0, 0, 0.65);width:6px;height:6px;border-radius:6px"></div>
            </div>
            <div style="font-weight:bold;font-size:14px">作品难度系数明细</div>
          </div>
          <div class="coef_item" v-for="(item, formIndex) in diffItem.difficulties" :key="formIndex">
            <div style="width:100%;padding-top:15px;display:flex;justify-content:space-between;align-items:center">
              <div style="font-size:14px;display:flex">
                <div style="width:35px;">
                  {{ `${numbers[formIndex] || formIndex + 1}、` }}
                </div>
                <div>{{ item.title }}</div>
              </div>
              <div>{{ item.factorValue }}</div>
            </div>
            <div style="max-width:100%;">
              <div
                style="width:100%;padding-top:15px;display:flex;justify-content:space-between;align-items:center"
                v-for="(child, childIndex) in item.categories"
                :key="child.id"
              >
                <div style="display:flex;font-size:0.7vw;width:15vw">
                  <div style="width:35px">{{ `${formIndex + 1}.${childIndex + 1}` }}</div>
                  <div>{{ child.factor.smallType }}</div>
                </div>
                <div style="font-size:0.7vw;display:flex;flex:1;justify-content:space-between">
                  <div style="display:flex;justify-content:space-between">
                    {{ ' ' }}
                    <div style="display:flex">
                      <div style="width:35px;"></div>
                      <div>
                        {{ child.formMatchValue || '' }}
                        {{
                          child.options[0].range && child.formMatchValue / 2 ? getCalcRange(child.options[0].range) : ''
                        }}
                      </div>
                    </div>
                  </div>
                  {{ child.factorValue }}
                </div>
              </div>
            </div>
          </div>
          <div class="coef_item" style="padding-top:5px;padding-bottom:40px;">
            <div style="width:100%;padding-top:15px;display:flex;justify-content:space-between;align-items:center">
              <div style="font-weight:bold;font-size:14px;display:flex">
                <div style="width:35px"></div>
                <div>作品难度系数</div>
              </div>
              <div style="font-size:14px;font-weight:bold">
                {{ diffItem.difficultyFactorValue }}
              </div>
            </div>
          </div>
        </div> -->
        <div v-for="factor in formData.factors" :key="factor.id">
          <div class="coef_item" style="display:flex">
            <div style="width:35px;display:flex;justify-content:space-between;align-items:center">
              <div style="background-color:rgba(0, 0, 0, 0.65);width:6px;height:6px;border-radius:6px"></div>
            </div>
            <div style="font-weight:bold;font-size:14px">单拎系数明细</div>
          </div>
          <div class="coef_item" v-for="(item, formIndex) in factor.difficulties" :key="item.id">
            <div style="width:100%;padding-top:15px;display:flex;justify-content:space-between;align-items:center">
              <div style="font-size:14px;display:flex">
                <div style="width:35px;">
                  {{ `${numbers[formIndex] || formIndex + 1}、` }}
                </div>
                <div>{{ item.title }}</div>
              </div>
              <div style="flex:1">
                <div style="width:100%;display:flex;justify-content:space-between;">
                  <div v-for="child in item.categories" :key="child.id" style="padding-left:35px">
                    <div>
                      {{ child.formMatchValue || '' }}
                      {{
                        child.options[0].range && child.formMatchValue / 2 ? getCalcRange(child.options[0].range) : ''
                      }}
                    </div>
                  </div>
                  <div>{{ item.factorValue }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="coef_item" style="display:flex;padding-top:35px">
          <div style="width:35px;display:flex;justify-content:space-between;align-items:center">
            <div style="background-color:rgba(0, 0, 0, 0.65);width:6px;height:6px;border-radius:6ox;"></div>
          </div>
          <div style="font-weight:bold;font-size:14px">系数结果</div>
        </div>
        <div style="display:flex;">
          <div style="width:35px;"></div>
          <div style="width:100%;padding-top:15px;display:flex;justify-content:space-between;align-items:center">
            <div>
              {{ formData && formData.formula && formData.formula.split('\n')[0].split('=')[1] }}
            </div>
            <div></div>
          </div>
        </div>
        <div style="display:flex;">
          <div style="width:35px;"></div>
          <div style="width:100%;padding-top:15px;display:flex;justify-content:space-between;align-items:center">
            <div>
              {{
                `1${formData.difficultyValue >= 0 ? '+' : ''}${formData.difficultyValue}${
                  formData.factorValue >= 0 ? '+' : ''
                }${formData.factorValue}`
              }}
            </div>
            <div>{{ formData && formData.sumResult }}</div>
          </div>
        </div>
        </template>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { postAction, getAction } from '@/api/manage'
import { mixinDevice } from '@/utils/mixin.js'
export default {
  mixins: [mixinDevice],
  props: {
    taskDetail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      formData: null,
      numbers: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'],
      params: {
        ...this.$route.params,
        record: this.$route.query.record
      },
      spinning: false
    }
  },
  mounted() {
    this.getFormData()
  },
  methods: {
    async getFormData() {
      this.spinning = true
      const { taskId, productionId, chapterId } = this.params
      const stageId = this.taskDetail.currentStage.id
      let draftStageId = ''
      let deliveryStageId = ''
      this.taskDetail.stageList.forEach(item => {
        if (item.stageName == '给稿') draftStageId = item.stageId
        if (item.stageName == '交稿') deliveryStageId = item.stageId
      })
      const res = await getAction('/factorView', {
        taskId,
        productionId,
        chapterId,
        stageId,
        draftStageId,
        deliveryStageId
      })
      this.formData = res.data
      this.spinning = false
    },
    getCalcRange(data) {
      let text = ''
      let unit = ''
      if (data.unit) {
        switch (data.unit) {
          case 'EACH':
            unit = '个'
            break
          case 'CHAPTER':
            unit = '话'
            break
          case 'VOLUME':
            unit = '部'
            break
          case 'PAGE':
            unit = '页'
            break
          case 'WORD':
            unit = '字'
            break
          case 'THOUSAND':
            unit = '千字'
            break
          case 'GRID':
            unit = '格'
            break
          case 'PERCENT':
            unit = '%'
            break
        }
      }
      return unit
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .coef_item .ant-form-item-control-input-content {
  display: flex !important;
}
::-webkit-scrollbar-button {
  height: 4px;
}
</style>
