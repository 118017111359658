var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { "overflow-y": "overlay", height: "62.2vh", width: "100%" },
    },
    [
      _c(
        "div",
        {
          style: _vm.isDesktop() ? "width:68%;min-width:520px;" : "width:100%;",
        },
        [
          _c(
            "a-spin",
            { attrs: { spinning: _vm.spinning } },
            [
              _vm.formData
                ? [
                    _vm._l(_vm.formData.factors, function (factor) {
                      return _c(
                        "div",
                        { key: factor.id },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "coef_item",
                              staticStyle: { display: "flex" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "35px",
                                    display: "flex",
                                    "justify-content": "space-between",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c("div", {
                                    staticStyle: {
                                      "background-color": "rgba(0, 0, 0, 0.65)",
                                      width: "6px",
                                      height: "6px",
                                      "border-radius": "6px",
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-weight": "bold",
                                    "font-size": "14px",
                                  },
                                },
                                [_vm._v("单拎系数明细")]
                              ),
                            ]
                          ),
                          _vm._l(
                            factor.difficulties,
                            function (item, formIndex) {
                              return _c(
                                "div",
                                { key: item.id, staticClass: "coef_item" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "padding-top": "15px",
                                        display: "flex",
                                        "justify-content": "space-between",
                                        "align-items": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "font-size": "14px",
                                            display: "flex",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticStyle: { width: "35px" } },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    `${
                                                      _vm.numbers[formIndex] ||
                                                      formIndex + 1
                                                    }、`
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          ),
                                          _c("div", [
                                            _vm._v(_vm._s(item.title)),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticStyle: { flex: "1" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "100%",
                                                display: "flex",
                                                "justify-content":
                                                  "space-between",
                                              },
                                            },
                                            [
                                              _vm._l(
                                                item.categories,
                                                function (child) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: child.id,
                                                      staticStyle: {
                                                        "padding-left": "35px",
                                                      },
                                                    },
                                                    [
                                                      _c("div", [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              child.formMatchValue ||
                                                                ""
                                                            ) +
                                                            "\n                    " +
                                                            _vm._s(
                                                              child.options[0]
                                                                .range &&
                                                                child.formMatchValue /
                                                                  2
                                                                ? _vm.getCalcRange(
                                                                    child
                                                                      .options[0]
                                                                      .range
                                                                  )
                                                                : ""
                                                            ) +
                                                            "\n                  "
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                              _c("div", [
                                                _vm._v(
                                                  _vm._s(item.factorValue)
                                                ),
                                              ]),
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      )
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "coef_item",
                        staticStyle: { display: "flex", "padding-top": "35px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "35px",
                              display: "flex",
                              "justify-content": "space-between",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("div", {
                              staticStyle: {
                                "background-color": "rgba(0, 0, 0, 0.65)",
                                width: "6px",
                                height: "6px",
                                "border-radius": "6ox",
                              },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              "font-size": "14px",
                            },
                          },
                          [_vm._v("系数结果")]
                        ),
                      ]
                    ),
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c("div", { staticStyle: { width: "35px" } }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            "padding-top": "15px",
                            display: "flex",
                            "justify-content": "space-between",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.formData &&
                                    _vm.formData.formula &&
                                    _vm.formData.formula
                                      .split("\n")[0]
                                      .split("=")[1]
                                ) +
                                "\n          "
                            ),
                          ]),
                          _c("div"),
                        ]
                      ),
                    ]),
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c("div", { staticStyle: { width: "35px" } }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100%",
                            "padding-top": "15px",
                            display: "flex",
                            "justify-content": "space-between",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  `1${
                                    _vm.formData.difficultyValue >= 0 ? "+" : ""
                                  }${_vm.formData.difficultyValue}${
                                    _vm.formData.factorValue >= 0 ? "+" : ""
                                  }${_vm.formData.factorValue}`
                                ) +
                                "\n          "
                            ),
                          ]),
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.formData && _vm.formData.sumResult)
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }